import React from "react";

import { Spin } from "antd";

import "antd/es/spin/style/css";

const styles = {
  child: {
    position: "absolute",
    top: "50%",
    left: "50%",
    margin: "0",
    textAlign: "center",
    zIndex: "1000",
    transform: "translateX(-50%) translateY(-50%)"
  }
};

export default class Loader extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return <Spin style={styles.child} size="large" />;
  }
}
