import React from "react";

import { indexAct } from "../api/indexAct";

import { Link } from "react-router-dom";

import { Card, message, Spin, Row, Col } from "antd";

import "antd/es/card/style/css";

export default class Gallery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: [],
    };
  }

  componentDidMount() {
    this.getGallery();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.lang !== prevProps.lang) {
      this.getGallery();
    }
  }

  getGallery = () => {
    indexAct("GET_GALLERY", {}, "/").then((data) => {
      if (data.code === 200) {
        this.setState((state) => {
          state.loading = false;
          state.data = data.res;
          return state;
        });
      } else {
        message.error("შეცდომა!", 5);
      }
    });
  };

  render() {
    const { loading, data } = this.state;
    return (
      <>
        <div className="ant-segment" id="gallery">
          <div className="ribbon">
            <span className="page-title-ribbon-content">
              {" "}
              {
                this.props.info.ROUTES.find((el) => el.COMPONENT === "Gallery")
                  .PAGE_NAME
              }
            </span>
          </div>
          <div className="page-content">
            {loading ? (
              <Spin
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <Row gutter={[16, 16]}>
                {data.map((el, i) => {
                  return (
                    <Col xs={12} sm={12} md={12} lg={6} xl={6} key={i}>
                      <Link to={`gallery/${el.URL}`}>
                        <Card
                          size="small"
                          cover={
                            <img
                              style={{
                                width: "100%",
                                maxWidth: "400px",
                                height: "auto",
                              }}
                              alt=""
                              src={
                                el.IMAGE.length > 0
                                  ? window.APP.MEDIA + el.IMAGE
                                  : `${window.APP.MEDIA}no-image.png`
                              }
                            />
                          }
                        >
                          <Card.Meta title={el.NAME} />
                        </Card>
                      </Link>
                    </Col>
                  );
                })}
              </Row>
            )}
          </div>
        </div>
      </>
    );
  }
}
