import React from "react";

import { indexAct } from "../api/indexAct";

import { message, Spin } from "antd";

export default class ProjectsDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      data: {},
    };
  }

  componentDidMount() {
    this.getProjectsDetails();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.lang !== prevProps.lang) {
      this.getProjectsDetails();
    }
  }

  getProjectsDetails = () => {
    indexAct(
      "GET_PROJECTS_DETAILS",
      {
        NAME: this.props.match.params.name,
      },
      "/"
    ).then((data) => {
      if (data.code === 200) {
        this.setState((state) => {
          state.loading = false;
          state.data = data.res;
          return state;
        });
      } else {
        message.error("შეცდომა!", 5);
      }
    });
  };

  render() {
    const { loading, data } = this.state;
    return (
      <>
        <div className="ant-segment" id="projects">
          <div className="ribbon">
            <span className="page-title-ribbon-content">{data.NAME}</span>
          </div>
          <div className="page-content">
            {loading ? (
              <Spin
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: data.DESCRIPTION,
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
