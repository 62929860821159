import React from "react";

import { indexAct } from "../api/indexAct";

import { message, Spin } from "antd";

export default class About extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      about: [],
    };
  }

  componentDidMount() {
    this.getAbout();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.lang !== prevProps.lang) {
      this.getAbout();
    }
  }

  getAbout = () => {
    indexAct("GET_ABOUT", {}, "/").then((data) => {
      if (data.code === 200) {
        this.setState((state) => {
          state.loading = false;
          state.about = data.res;
          return state;
        });
      } else {
        message.error("შეცდომა!", 5);
      }
    });
  };

  render() {
    const { loading, about } = this.state;
    return (
      <>
        <div className="ant-segment" id="about">
          <div className="ribbon">
            <span className="page-title-ribbon-content">
              {" "}
              {
                this.props.info.ROUTES.find((el) => el.COMPONENT === "About")
                  .PAGE_NAME
              }
            </span>
          </div>
          <div className="page-content">
            {loading ? (
              <Spin
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <div
                dangerouslySetInnerHTML={{
                  __html: about.ABOUT_DESCRIPTION,
                }}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}
