import React, { useState } from "react";
import { Drawer, Menu } from "antd";
import { MenuOutlined } from "@ant-design/icons";

import { Link } from "react-router-dom";
//import "./NavBar.css";

import "antd/es/menu/style/css";
import "antd/es/drawer/style/css";

const PagesMobile = (props) => {
  const [visible, setVisible] = useState(false);
  const [current, setCurrent] = useState(
    props.location.pathname.substring(
      0,
      props.location.pathname.lastIndexOf("/")
    ) || props.location.pathname
  );

  function getRoutes() {
    return props.info.ROUTES;
  }

  function renderMenu() {
    const menuArr = getRoutes();
    return menuArr.map((el) => {
      if (el.IS_NAV !== "1") {
        return null;
      }
      if (el.CHILDS.length === 0) {
        return (
          <Menu.Item key={el.URL}>
            <Link
              to={{
                pathname: el.URL,
              }}
            >
              {el.PAGE_NAME}
            </Link>
          </Menu.Item>
        );
      } else {
        return (
          <Menu.SubMenu
            title={
              <Link
                to={el.URL}
                onClick={() => {
                  setVisible(false);
                }}
              >
                {el.PAGE_NAME}
              </Link>
            }
            key={el.URL}
          >
            {el.CHILDS.map((elIn) => {
              return (
                <Menu.Item key={elIn.URL} style={{ fontFamily: "HeadFont" }}>
                  <Link to={elIn.URL}>{elIn.PAGE_NAME}</Link>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      }
    });
  }

  return (
    <nav className="navbar">
      {/* <Button
        className="menu"
        icon={<MenuOutlined />}
        onClick={() => setVisible(true)}
        style={{
          fontSize: "20px",
          marginLeft: "12px",
          verticalAlign: "middle",
        }}
      /> */}
      <MenuOutlined
        style={{
          fontSize: "20px",
          marginLeft: "12px",
          verticalAlign: "middle",
        }}
        onClick={() => setVisible(true)}
      />
      <Drawer
        title="&nbsp;"
        placement="left"
        //onClick={() => setVisible(false)}
        onClose={() => setVisible(false)}
        visible={visible}
        bodyStyle={{ padding: 0 }}
      >
        <Menu
          onClick={(e) => {
            setVisible(false);
            setCurrent(e.key);
          }}
          selectedKeys={[current]}
          mode="inline"
          className="side-bar-menu"
        >
          {renderMenu()}
        </Menu>
      </Drawer>
    </nav>
  );
};

export default PagesMobile;
