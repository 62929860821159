import axios from "axios";

export function indexAct(act, data, url = null) {
  return axios({
    method: "post",
    url: window.APP.API + "index.php",
    headers: {
      "content-type": "application/x-www-form-urlencoded;charset=UTF-8",
    },
    withCredentials: true,
    data: {
      act: act,
      data: data,
      url: url || window.location.pathname,
    },
  })
    .then((res) => {
      return res.data;
    })
    .catch((error) => {
      console.log(error);
    });
}
