import React from "react";

import { Menu } from "antd";

import "antd/es/menu/style/css";

import { Link } from "react-router-dom";

export default class Pages extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      current:
        this.props.location.pathname.substring(
          0,
          this.props.location.pathname.lastIndexOf("/")
        ) || this.props.location.pathname,
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.state.current !== "/") {
      if (this.props.location.pathname === "/") {
        this.handleClick({
          key: "/",
        });
      }
    }
  }

  handleClick = (e) => {
    this.setState({
      current: e.key,
    });
  };

  getRoutes = () => {
    return this.props.info.ROUTES;
    /* return [
      {
        PAGE_ID: 3,
        PARENT_ID: null,
        PAGE_NAME: "პროდუქცია",
        PARENT_NAME: null,
        URL: "/products",
        PARENT_URL: null,
        CHILDS: [],
      },
      {
        PAGE_ID: 1,
        PARENT_ID: null,
        PAGE_NAME: "სიახლეები",
        PARENT_NAME: null,
        URL: "/news",
        PARENT_URL: null,
        CHILDS: [],
      },
      {
        PAGE_ID: 2,
        PARENT_ID: null,
        PAGE_NAME: "მომსახურება",
        PARENT_NAME: null,
        URL: "/service",
        PARENT_URL: null,
        CHILDS: [],
      },
      {
        PAGE_ID: 4,
        PARENT_ID: null,
        PAGE_NAME: "ჩვენს შესახებ",
        PARENT_NAME: null,
        URL: "/about",
        PARENT_URL: null,
        CHILDS: [],
      },
      {
        PAGE_ID: 5,
        PARENT_ID: null,
        PAGE_NAME: "დაგვიკავშირდით",
        PARENT_NAME: null,
        URL: "/contact",
        PARENT_URL: null,
        CHILDS: [],
      },
    ]; */
  };

  renderMenu = () => {
    const menuArr = this.getRoutes();
    return menuArr.map((el) => {
      if (el.IS_NAV !== "1") {
        return null;
      }
      if (el.CHILDS.length === 0) {
        return (
          <Menu.Item key={el.URL}>
            <Link
              to={{
                pathname: el.URL,
              }}
            >
              {el.PAGE_NAME}
            </Link>
          </Menu.Item>
        );
      } else {
        return (
          <Menu.SubMenu
            title={el.PAGE_NAME}
            key={el.URL}
            onTitleClick={() => {
              this.props.history.push({
                pathname: el.URL,
              });
            }}
          >
            {el.CHILDS.map((elIn) => {
              return (
                <Menu.Item key={elIn.URL} style={{ fontFamily: "HeadFont" }}>
                  <Link to={elIn.URL}>{elIn.PAGE_NAME}</Link>
                </Menu.Item>
              );
            })}
          </Menu.SubMenu>
        );
      }
    });
  };

  render() {
    return (
      <Menu
        onClick={this.handleClick}
        selectedKeys={[this.state.current]}
        mode="horizontal"
        forceSubMenuRender
        style={{
          ///lineHeight: "40px",
          textAlign: "right",
          //background: "#C6CFD9",
          border: "none",
        }}
        className="top-menu"
      >
        {this.renderMenu()}
      </Menu>
    );
  }
}
