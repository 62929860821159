import React from "react";

import NewsHome from "../news/NewsHome";
import ContactHome from "../contact/ContactHome";

import { Carousel, Row, Col } from "antd";

import device from "../../functions/device";

//import "antd/es/carousel/style/css";

export default class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      device: device(),
    };
  }

  componentDidMount() {}

  componentDidUpdate(prevProps, prevState, snapshot) {}

  render() {
    return (
      <>
        <Row justify="center">
          <Col span={24}>
            <Carousel
              slidesToShow={1}
              autoplay={true}
              pauseOnHover
              dots={false}
              draggable
              responsive={[
                {
                  breakpoint: 600,
                  settings: {
                    slidesToShow: 1,
                  },
                },
              ]}
            >
              {this.props.info.SLIDER.map((el, i) => {
                return (
                  <div
                    key={i}
                    className="home-car-center"
                    style={{ width: "100%" }}
                  >
                    <img
                      style={{
                        width: "100%",
                        height: this.state.device.isMobile ? "100%" : "500px",
                      }}
                      alt=""
                      src={`${window.APP.MEDIA}${el.IMAGE}`}
                    />
                  </div>
                );
              })}
            </Carousel>
          </Col>
        </Row>
        <NewsHome {...this.props} />
        <ContactHome {...this.props} />
      </>
    );
  }
}
