import React from "react";

import { Switch, Route, Redirect } from "react-router-dom";

import Home from "./home";
import News from "./news";
import NewsDetails from "./news/NewsDetails";
import Projects from "./projects";
import ProjectsDetails from "./projects/ProjectsDetails";
import Announcements from "./announcements";
import AnnouncementsDetails from "./announcements/AnnouncementsDetails";
import About from "./about";
import Contact from "./contact";
import Gallery from "./gallery";
import GalleryDetails from "./gallery/GalleryDetails";
import CreatedPage from "./created-page";

import "antd/es/divider/style/css";
import "antd/es/button/style/css";
import "antd/es/list/style/css";
import "antd/es/modal/style/css";
import "antd/es/cascader/style/css";

const components = {
  News,
  NewsDetails,
  About,
  Contact,
  Projects,
  ProjectsDetails,
  Announcements,
  AnnouncementsDetails,
  Gallery,
  GalleryDetails,
  CreatedPage,
};

class ContentIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    return (
      <Switch>
        <Route
          path="/"
          exact
          render={(route_props) => {
            return <Home {...this.props} {...route_props} />;
          }}
        />
        {this.props.info.ROUTES.map((el) => {
          const Comp = components[el.COMPONENT];
          return (
            <Route
              path={`${el.URL}`}
              exact
              key={el.COMPONENT}
              render={(route_props) => {
                return <Comp {...this.props} {...route_props} />;
              }}
            />
          );
        })}
        <Route
          path="*"
          render={(route_props) => (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          )}
        />
        {/* <Route
          render={(route_props) => {
            return "NoMatch";
          }}
        /> */}
      </Switch>
    );
  }
}

export default ContentIn;
