import React from "react";

import { indexAct } from "../api/indexAct";

import device from "../../functions/device";

import { Divider, Row, Col, Carousel, message, Spin } from "antd";

import Icon, {
  PhoneFilled,
  MailFilled,
  //ForwardOutlined,
  //BackwardOutlined,
} from "@ant-design/icons";

const MapSvg = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    fill="currentColor"
  >
    <g>
      <g>
        <path
          d="M256,0C153.755,0,70.573,83.182,70.573,185.426c0,126.888,165.939,313.167,173.004,321.035
              c6.636,7.391,18.222,7.378,24.846,0c7.065-7.868,173.004-194.147,173.004-321.035C441.425,83.182,358.244,0,256,0z M256,278.719
              c-51.442,0-93.292-41.851-93.292-93.293S204.559,92.134,256,92.134s93.291,41.851,93.291,93.293S307.441,278.719,256,278.719z"
        />
      </g>
    </g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
    <g></g>
  </svg>
);

const MapIcon = (props) => <Icon component={MapSvg} {...props} />;

const FaxSvg = () => (
  <svg
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    x="0px"
    y="0px"
    viewBox="0 0 512 512"
    fill="currentColor"
  >
    <g>
      <path d="m180 136c0-33.091-26.909-60-60-60s-60 26.909-60 60v301c0 33.091 26.909 60 60 60s60-26.909 60-60z" />
      <path d="m271 212h150v30h-150z" />
      <path d="m497 121h-288.516c.831 4.903 1.516 9.864 1.516 15v301c0 31.309-16.108 58.872-40.432 75h327.432c8.291 0 15-6.709 15-15v-361c0-8.291-6.709-15-15-15zm-211 331c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm60 120c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm60 120c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm0-60c-8.284 0-15-6.716-15-15 0-8.286 6.716-15 15-15s15 6.714 15 15c0 8.284-6.716 15-15 15zm45-75c0 8.291-6.709 15-15 15h-180c-8.291 0-15-6.709-15-15v-60c0-8.291 6.709-15 15-15h180c8.291 0 15 6.709 15 15z" />
      <path d="m436 0h-180c-8.291 0-15 6.709-15 15v75h210v-75c0-8.291-6.709-15-15-15zm-60 60h-60c-8.291 0-15-6.709-15-15s6.709-15 15-15h60c8.291 0 15 6.709 15 15s-6.709 15-15 15z" />
      <path d="m30 437v-301c0-5.136.685-10.097 1.516-15h-16.516c-8.291 0-15 6.709-15 15v361c0 8.291 6.709 15 15 15h55.432c-24.324-16.128-40.432-43.691-40.432-75z" />
    </g>
  </svg>
);

const FaxIcon = (props) => <Icon component={FaxSvg} {...props} />;

/* function PrtNextArrow(props) {
  const { onClick } = props;
  return (
    <Button
      className="prtBtn prtBtn-next"
      type="dashed"
      shape="circle"
      icon={<ForwardOutlined />}
      onClick={onClick}
    />
  );
}

function PrtPrevArrow(props) {
  const { onClick } = props;
  return (
    <Button
      className="prtBtn prtBtn-prev"
      type="dashed"
      shape="circle"
      icon={<BackwardOutlined />}
      onClick={onClick}
    />
  );
} */

const headTitle = {
  TITLE_GE: "პარტნიორები",
  TITLE_EN: "Partners",
};

export default class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      contacts: {},
      company: [],
      device: device(),
    };
  }

  componentDidMount() {
    this.getContacts();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.lang !== prevProps.lang) {
      this.getContacts();
    }
  }

  getContacts = () => {
    indexAct("GET_CONTACTS", {}, "/").then((data) => {
      if (data.code === 200) {
        this.setState((state) => {
          state.loading = false;
          state.contacts = data.res.contacts;
          state.company = data.res.company;
          return state;
        });
      } else {
        message.error("შეცდომა!", 5);
      }
    });
  };

  render() {
    const { loading, contacts, company } = this.state;

    return (
      <>
        <div className="ant-segment" id="contact">
          <div className="ribbon">
            <span className="page-title-ribbon-content">
              {" "}
              {
                this.props.info.ROUTES.find((el) => el.COMPONENT === "Contact")
                  .PAGE_NAME
              }
            </span>
          </div>
          <div className="page-content">
            {loading ? (
              <Spin
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <>
                <Divider style={{ marginTop: 0 }}> </Divider>
                <div className="contact-info">
                  <Row justify="center" gutter={[8, 8]}>
                    <Col xs={22} sm={24} md={5} lg={5} xl={5}>
                      <div>
                        <div className="contact-info-col-icon">
                          <MapIcon
                            style={{ width: "30px", color: "#25438B" }}
                          />
                        </div>
                        <div className="contact-info-col-content">
                          <h3>{contacts.ADDRESS_NAME}</h3>
                          {contacts.ADDRESS.split("|").map((el, i) => {
                            return <p key={i}>{el}</p>;
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col xs={22} sm={24} md={5} lg={5} xl={5}>
                      <div>
                        <div className="contact-info-col-icon">
                          <PhoneFilled
                            style={{ fontSize: "30px", color: "#25438B" }}
                          />
                        </div>
                        <div className="contact-info-col-content">
                          <h3>{contacts.PHONE_NAME}</h3>
                          {contacts.PHONE.split("|").map((el, i) => {
                            return <p key={i}>{el}</p>;
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col xs={22} sm={24} md={5} lg={5} xl={5}>
                      <div>
                        <div className="contact-info-col-icon">
                          <FaxIcon
                            style={{ width: "30px", color: "#25438B" }}
                          />
                        </div>
                        <div className="contact-info-col-content">
                          <h3>{contacts.FAX_NAME}</h3>
                          {contacts.FAX.split("|").map((el, i) => {
                            return <p key={i}>{el}</p>;
                          })}
                        </div>
                      </div>
                    </Col>
                    <Col xs={22} sm={24} md={5} lg={5} xl={5}>
                      <div>
                        <div className="contact-info-col-icon">
                          <MailFilled
                            style={{ fontSize: "30px", color: "#25438B" }}
                          />
                        </div>
                        <div className="contact-info-col-content">
                          <h3>{contacts.EMAIL_NAME}</h3>
                          {contacts.EMAIL.split("|").map((el, i) => {
                            return (
                              <p key={i}>
                                <a href={`mailto:${el}`}>{el}</a>
                              </p>
                            );
                          })}
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                {/* <Divider> </Divider>
                <div>
                  <iframe
                    title="map"
                    src={contacts.MAP_URL}
                    style={{
                      width: "100%",
                      height: this.state.device.isMobile ? "250px" : "500px",
                      frameborder: "0",
                      border: "0",
                    }}
                  ></iframe>
                </div> */}
                <Divider style={{ fontFamily: "HeadFont" }}>
                  {headTitle["TITLE_" + this.props.lang]}
                </Divider>
                <Row justify="center">
                  <Col span={24}>
                    <Carousel
                      slidesToShow={company.length < 5 ? company.length : 4}
                      slidesToScroll={2}
                      autoplay={true}
                      pauseOnHover
                      dots={false}
                      //arrows
                      //nextArrow={<PrtNextArrow />}
                      //prevArrow={<PrtPrevArrow />}
                      draggable
                      responsive={[
                        {
                          breakpoint: 600,
                          settings: {
                            slidesToShow: 2,
                            slidesToScroll: 2,
                          },
                        },
                      ]}
                    >
                      {company.map((el, i) => {
                        const image =
                          el.COMPANY_LOGO.length > 5
                            ? `${window.APP.MEDIA}${el.COMPANY_LOGO}`
                            : `${window.APP.MEDIA}no-image.png`;
                        return (
                          <div className="partner-logos" key={i}>
                            <div
                              style={{
                                textAlign: "center",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                height: "100px",
                              }}
                            >
                              <a
                                href={el.WEB}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ display: "inline-block" }}
                              >
                                <img
                                  alt="example"
                                  src={image}
                                  style={{ width: "100px" }}
                                />
                              </a>
                            </div>
                          </div>
                        );
                      })}
                    </Carousel>
                  </Col>
                </Row>
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
