import React from "react";

import { indexAct } from "../api/indexAct";

import "./index.css";

import { message, Spin, Row, Col } from "antd";

import ReactBnbGallery from "react-bnb-gallery";

import "react-bnb-gallery/dist/style.css";

export default class GalleryDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      showGallery: false,
      activePhotoIndex: 0,
      data: {},
    };
  }

  componentDidMount() {
    this.getGalleryDetails();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.lang !== prevProps.lang) {
      this.getGalleryDetails();
    }
  }

  getGalleryDetails = () => {
    indexAct(
      "GET_GALLERY_DETAILS",
      {
        NAME: this.props.match.params.name,
      },
      "/"
    ).then((data) => {
      if (data.code === 200) {
        data.res.IMAGES.forEach((el) => {
          el.photo = window.APP.MEDIA + el.photo;
        });

        this.setState((state) => {
          state.loading = false;
          state.data = data.res;
          return state;
        });
      } else {
        message.error("შეცდომა!", 5);
      }
    });
  };

  render() {
    const { loading, data, showGallery, activePhotoIndex } = this.state;
    return (
      <>
        <div className="ant-segment" id="gallery">
          <div className="ribbon">
            <span className="page-title-ribbon-content">{data.NAME}</span>
          </div>
          <div className="page-content">
            {loading ? (
              <Spin
                style={{
                  width: "100%",
                  height: "100%",
                }}
              />
            ) : (
              <>
                <Row gutter={[16, 16]} align="middle">
                  {data.IMAGES.map((el, i) => {
                    return (
                      <Col xs={12} sm={12} md={12} lg={4} xl={4} key={i}>
                        <img
                          style={{
                            width: "100%",
                            maxWidth: "400px",
                            height: "auto",
                            borderRadius: "6px",
                          }}
                          alt=""
                          src={el.photo}
                          onClick={() => {
                            this.setState((prevState) => ({
                              showGallery: true,
                              activePhotoIndex: i,
                            }));
                          }}
                        />
                      </Col>
                    );
                  })}
                </Row>

                <ReactBnbGallery
                  activePhotoIndex={activePhotoIndex}
                  show={showGallery}
                  photos={data.IMAGES}
                  onClose={() => {
                    this.setState((prevState) => ({
                      showGallery: false,
                    }));
                  }}
                />
              </>
            )}
          </div>
        </div>
      </>
    );
  }
}
